import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '@/views/login'

Vue.use(VueRouter)
const routes = [
  // {path:'/login',name:'login',component:Login,meta:{notLogin:true}},
  {path:'/login',name:'login',component:()=>import('@/views/login.vue'),meta:{notLogin:true}},
  {path:'/', redirect:'/default'},
  {path:'/default',name:'default',component:()=>import('@/views/default.vue')},

  {path:'/org/class',name:'org-class',component:()=>import('@/views/org/class/index.vue')},
  {path:'/org/teacher',name:'org-teacher',component:()=>import('@/views/org/teacher/index.vue')},

  {path:'/fx/dict',name:'fx-dict',component:()=>import('@/views/fx/dict/index.vue')},
  {path:'/fix/cat',name:'fix-cat',component:()=>import('@/views/fix/cat/index.vue')},
  {path:'/fix/location',name:'fix-location',component:()=>import('@/views/fix/location/index.vue')},
  {path:'/fix/note',name:'fix-note',component:()=>import('@/views/fix/note/index.vue')},
  {path:'/fix/mender',name:'fix-mender',component:()=>import('@/views/fix/mender/index.vue')},

  {path:'/mat/cat',name:'mat-cat',component:()=>import('@/views/mat/cat/index.vue')},
  {path:'/mat/stock',name:'mat-stock',component:()=>import('@/views/mat/stock/index.vue')},
  {path:'/mat/bill/:tp',name:'mat-bill',component:()=>import('@/views/mat/bill/index.vue')},

  {path:'/mat/valid/act',name:'mat-valid-act',component:()=>import('@/views/mat/valid/act/index.vue')},
  {path:'/mat/valid/conf',name:'mat-valid-conf',component:()=>import('@/views/mat/valid/conf/index.vue')},

  {path:'/cap/info',name:'cap-info',component:()=>import('@/views/cap/info/index.vue')},

  {path:'/doc/archive',name:'doc-archive',component:()=>import('@/views/doc/archive/index.vue')},
  {path:'/doc/content',name:'doc-content',component:()=>import('@/views/doc/content/index.vue')},

  {path:'/safe/conf',name:'safe-conf',component:()=>import('@/views/safe/conf/index.vue')},
  {path:'/safe/rec',name:'safe-rec',component:()=>import('@/views/safe/rec/index.vue')},
  {path:'/safe/rep',name:'safe-rep',component:()=>import('@/views/safe/rep/index.vue')},

  {path:'/msg/notice',name:'fix-notice',component:()=>import('@/views/msg/notice/index.vue')},
  {path:'/wfl/conf',name:'wfl-conf',component:()=>import('@/views/wfl/conf/index.vue')},
  {path:'/wfl/index',name:'wfl-index',component:()=>import('@/views/wfl/index.vue')},
  {path:'/wfl/act',name:'wfl-act',component:()=>import('@/views/wfl/act/index.vue')},
  {path:'/wfl/act/pro',name:'wfl-act-pro',component:()=>import('@/views/wfl/act/pro.vue')},
  {path:'/wfl/act/lst',name:'wfl-act-lst',component:()=>import('@/views/wfl/act/lst.vue')},

  {path:'/rent/index',name:'rent',component:()=>import('@/views/rent/index.vue')},

  {path:'/utl/rent',name:'utl-rent',component:()=>import('@/views/utl/rent/index.vue')},

  {path:'/meet/info',name:'meet-info',component:()=>import('@/views/meet/info/index.vue')},
  {path:'/meet/conf',name:'meet-conf',component:()=>import('@/views/meet/conf/index.vue')},

  {path:'/utl/test',name:'utl-test',component:()=>import('@/views/utl/test.vue')}
]

const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{
  if (!to.meta.notLogin) {
    let token = localStorage.getItem('loginToken') || '';
    let timestamp = (new Date()).getTime();
    let expires = localStorage.getItem('tokenExpires') || 0;
    // console.log(token,expires,timestamp)
    if (token&&expires>timestamp) {
      next()
    } else {
      next({
        path: '/login',
       })
    }
  } else {
    next()
  }
})
export default router
