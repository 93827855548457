/**
 * Created by whale on 2021/03/26.
 */
import axios from "axios";
import Swal from "sweetalert2";
import Compressor from "compressorjs";
export default {
  install: function(Vue) {
    Vue.prototype.whale = {
      remote: {
        getResult: function(opt) {
          axios.defaults.withCredentials = true
          let url = opt.url;
          // if (process.env.NODE_ENV==='development') url='/api'+opt.url;
          axios
            .post(url, JSON.stringify(opt.data || {}), {
              headers: {Authorization: localStorage.getItem("loginToken")},
            })
            .then(function(resp) {
              console.log("resp", resp.data);
              if (resp.data["Succeeded"]) {
                if (opt.completed !== undefined)
                  opt.completed(resp.data["Data"]);
              } else {
                if (opt.failure !== undefined) opt.failure(resp.data["Errors"]);
                else {
                  Swal.fire({
                    title: "出错啦",
                    text: resp.data["Errors"],
                    icon: "error",
                    cancelButtonText: "关闭",
                    showCancelButton: true,
                    showConfirmButton: false,
                  });
                }
              }
              if (opt.finally !== undefined) opt.finally();
            })
            .catch(function(error) {
              console.log("remote", error);
            });
        },
        getCollection: function(opt) {
          let url = opt.url;
          // if (process.env.NODE_ENV==='development') url='/api'+opt.url;
          axios
            .post(url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              console.log("coll", resp);
              if (resp.data["Succeeded"]) {
                if (opt.completed !== undefined)
                  opt.completed(
                    resp.data.Data.DATA.ITEMS,
                    resp.data.Data.DATA.TOTAL
                  );
              } else {
                if (opt.failure !== undefined) opt.failure(resp.data.MESSAGE);
                else console.log("remote", resp.data["MESSAGE"]);
              }
            })
            .catch((e) => console.log("fetch error", e));
        },
        getThird: function(opt) {
          axios
            .post(opt.url, opt.params, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(function(resp) {
              if (opt.finally !== undefined) opt.finally(resp.data);
            })
            .catch(function(error) {
              console.log("remote", error);
            });
        },
        getExport: function(opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
              responseType: "blob",
            })
            .then((resp) => {
              console.log(resp);
              if (resp.data.type == "application/json") {
                Swal.fire({
                  title: "出错啦",
                  text: resp.data["Errors"],
                  icon: "error",
                  cancelButtonText: "关闭",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
              } else {
                opt.completed(resp.data);
              }
            });
        },
        import: function(opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              console.log(resp.data);
              opt.completed(resp.data.Data);
            });
        },
      },
      grade: {
        lst: [
          { value: 2, name: "小班" },
          { value: 3, name: "中班" },
          { value: 4, name: "大班" },
        ],
        getName: function(code) {
          console.log("class", code);
          if (code == null) return "";
          let ret = "";
          for (let g of this.lst) {
            if (code === g.value) {
              ret = g.name;
              // return g.name;
            }
          }
          console.log(ret);
          return ret;
        },
      },
      weeks: [
        { name: "周日", value: "0" },
        { name: "周一", value: "1" },
        { name: "周二", value: "2" },
        { name: "周三", value: "3" },
        { name: "周四", value: "4" },
        { name: "周五", value: "5" },
        { name: "周六", value: "6" },
      ],
      area: [
        { name: "市辖区", value: "330301" },
        { name: "鹿城区", value: "330302" },
        { name: "龙湾区", value: "330303" },
        { name: "瓯海区", value: "330304" },
        { name: "洞头区", value: "330305" },
        { name: "永嘉县", value: "330324" },
        { name: "平阳县", value: "330326" },
        { name: "苍南县", value: "330327" },
        { name: "文成县", value: "330328" },
        { name: "泰顺县", value: "330329" },
        { name: "温州经济技术开发区", value: "330371" },
        { name: "瑞安市", value: "330381" },
        { name: "乐清市", value: "330382" },
      ],
      roles: {
        ADMIN: "学校管理员",
        HEALTH: "保健教师",
      },
      delConfirm: (fn) => {
        Swal.fire({
          text: "真的要删除这条记录吗？",
          icon: "question",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showCancelButton: true,
        }).then((v) => {
          if (v.isConfirmed) fn();
        });
      },
      downLoad(url) {
        var downloadElement = document.createElement("a");
        downloadElement.style.display = "none";
        downloadElement.href = "/api" + url;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      },
      toast: {
        err(msg) {
          Swal.fire({
            title: "出错啦",
            text: msg,
            icon: "error",
            cancelButtonText: "关闭",
            showCancelButton: true,
            showConfirmButton: false,
          });
        },
        info(msg) {
          Swal.fire({
            text: msg,
            icon: "info",
            cancelButtonText: "关闭",
            showCancelButton: true,
            showConfirmButton: false,
          });
        },
      },
      image: {
        compress(opt) {
          if (opt.quality === undefined) opt.quality = 0.2;
          new Compressor(opt.file, {
            quality: opt.quality,
            convertSize: 1048576,
            success(ret) {
              opt.callback(ret);
            },
            error(err) {
              alert(err);
            },
          });
        },
        toB64(opt) {
          let reader = new FileReader();
          reader.readAsDataURL(opt.file);
          reader.onload = (ev) => {
            let arr = ev.target.result.split(",");
            opt.callback(arr[1]);
          };
        },
      },
      user: {
        get() {
          return JSON.parse(localStorage.getItem("usrInfo"));
        },
      },
    };
  },
};
