<template>
  <div class="wrapper">
    <!-- Left side column. contains the logo and sidebar -->
    <div class="left_aside">
      <!-- sidebar: style can be found in sidebar-->
      <div class="sidebar">
        <!--user profile section
        <div class="user_section"></div>-->
        <div id="menu" role="navigation">
          <!-- / .navigation -->
          <Nav />
        </div>
        <!-- menu -->
      </div>
      <!-- /.sidebar -->
    </div>
    <!-- /.content -->
    <div class="right-aside view-port-height">
      <router-view :key="key" />
    </div>
  </div>
</template>
<style>
.w-block {
  border-radius: 4px;
  background: #f5f5f5;
  color: #333;
  margin: 5px 0px;
  border: solid 1px #ccc
}

.el-table th {
  color: #333;
}

.el-table td {
  height: 40px
}

.el-dialog__wrapper {
  z-index: 999
}

.el-dialog__header {
  border-bottom: solid 1px #eceeef;
  background-color: #fcb410 !important;
  line-height: 24px;
  font-size: 18px;
  color: #fff;
}

.el-dialog__headerbtn {
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5;
  background: transparent;
}

.el-dialog__footer {
  border-top: 1px solid #eceeef
}

.el-dialog__title {
  color: #fff
}

.swal2-container.swal2-center {
  z-index: 5000;
}

button:focus {
  outline: none
}
</style>

<script>
import Nav from "./components/wnav";
export default {
  components: { Nav },
  // created() {
  //   let self = this;
  //   this.whale.remote.getResult({
  //     url: "/api/School/ORG/Teacher/Login/61222B8F-8389-4A5C-BDEB-CD1830493980",
  //     // url:'/api/School/ORG/Teacher/Login/A1A2A300-0000-6666-5555-C5D79DD2A001',
  //     completed(m) {
  //       console.log('login', m)
  //       localStorage.setItem("loginToken", m.jwt.token);
  //       // self.whale.user.set(m.user);
  //       localStorage.setItem('userInfo', JSON.stringify(m.user))
  //       localStorage.setItem("tokenExpires", m.jwt.ExpiresLong);
  //       self.$router.push("/")
  //     }
  //   })
  // },
  mounted() { },
  computed: {
    key() { return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date() }
  }
}
</script>
